import React, { FC } from 'react';
import styles from './header.module.scss';
import { useLocation } from 'react-router-dom';
import { useSettings } from 'hooks/SettingsContext';

const Header: FC = () => {
    const { pathname } = useLocation();
    const isCheckInPage = pathname === '/check-in';
    const { settings } = useSettings();
    return (
        <div
            className={`${styles.wrapper} ${
                isCheckInPage ? styles.withoutBackground : styles.withBackground
            }`}
        >
            {settings?.logo && <img src={settings?.logo} alt="logo" className={styles.logo} />}
        </div>
    );
};
export default Header;
