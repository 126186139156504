import { initializeApp } from 'firebase/app';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    Timestamp,
    updateDoc,
    where,
} from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
    getAuth,
    signInWithEmailAndPassword,
    signInAnonymously,
    UserCredential,
    browserSessionPersistence,
    setPersistence,
} from 'firebase/auth';
import { Appointment, Clinic } from 'types';
import moment from 'moment';
import 'moment/locale/sv';
import { encrypt } from './crypto';

moment.locale('se');

const firebaseConfig = {
    apiKey: 'AIzaSyBWery0TdPRKVyNtZBW1x6P9_as0olnX2w',
    authDomain: 'ehealth-v2.firebaseapp.com',
    databaseURL: 'https://ehealth-v2-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'ehealth-v2',
    storageBucket: 'ehealth-v2.appspot.com',
    messagingSenderId: '1092247891639',
    appId: '1:1092247891639:web:3e9b1bff695aaae129a732',
    measurementId: 'G-28WHJBRSHR',
};

export const organization = 'care.hyperstack.se';

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);

export const analytics = getAnalytics(app);

export const signInWithEmailPassword = async (
    email: string,
    password: string
): Promise<UserCredential> => {
    await setPersistence(authentication, browserSessionPersistence);
    return signInWithEmailAndPassword(authentication, email, password);
};

export const signInAnonymouslyQR = async (clinicString: string): Promise<UserCredential | null> => {
    await setPersistence(authentication, browserSessionPersistence);
    const db = getFirestore();
    const docRef = doc(db, 'clinics', clinicString);
    const docSnapshot = await getDoc(docRef);
    if (!docSnapshot.exists) return null;
    const clinic = docSnapshot.data() as Clinic;
    if (!clinic.active) return null;
    return signInAnonymously(authentication);
};

export const checkInPatient = async (securityNumber: string, clinic: string): Promise<string> => {
    const currentTimestamp = new Timestamp(Date.now() / 1000, 0);
    const db = getFirestore();
    const colAppointments = collection(db, 'appointments');
    const encryptedSecurityNumber = encrypt(securityNumber);
    const appointmentQuery = query(
        colAppointments,
        where('patient.socialSec', '==', encryptedSecurityNumber),
        where('status', '==', 'created'),
        where('appointmentAt', '>=', currentTimestamp),
        orderBy('appointmentAt')
    );

    const snapshot = await getDocs(appointmentQuery);
    const appointments = snapshot.docs.map((appointment) => {
        return { id: appointment.id, ...appointment.data() };
    }) as Appointment[];

    const appointmentTodayClosestInTime = appointments[0];

    if (appointmentTodayClosestInTime) {
        const docRef = doc(db, 'appointments', appointmentTodayClosestInTime.id);
        await updateDoc(docRef, {
            status: 'checked in',
            updatedAt: new Timestamp(Date.now() / 1000, 0),
        });
        logEvent(analytics, 'check-in-successful', {
            clinic,
            patient: encryptedSecurityNumber,
        });
        return docRef.id;
    }
    const docRef = await addDoc(colAppointments, {
        clinic,
        appointmentAt: new Timestamp(Date.now() / 1000, 0),
        createdAt: new Timestamp(Date.now() / 1000, 0),
        updatedAt: new Timestamp(Date.now() / 1000, 0),
        patient: { socialSec: encryptedSecurityNumber },
        status: 'checked in',
        published: null,
        type: 'unscheduled',
    });
    logEvent(analytics, 'check-in-successful', { clinic, patient: encryptedSecurityNumber });
    return docRef.id;
};
