import React, { FC, useEffect, useState } from 'react';
import Header from 'components/Header';
import SurveyInputForm from 'components/SurveyInputForm';
import { useTranslation } from 'react-i18next';
import styles from './survey.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { DocumentReference, getDoc } from 'firebase/firestore';
import { SurveyQuestion } from 'types';

const Survey: FC = () => {
    const { t } = useTranslation();
    const { settings } = useSettings();
    const [surveyQuestions, setSurveyQuestions] = useState<SurveyQuestion[]>();
    useEffect(() => {
        if (!settings) return;
        const getQuestions = async (docRefs: DocumentReference[]) => {
            const questionPromises = docRefs.map((docRef) =>
                getDoc(docRef).then((docSnap) => {
                    return { id: docSnap.id, ...docSnap.data() } as SurveyQuestion;
                })
            );
            const questionsDocs = await Promise.all(questionPromises);
            return questionsDocs;
        };
        // eslint-disable-next-line promise/catch-or-return
        getQuestions(settings.features.surveyQuestions).then(setSurveyQuestions);
    }, [settings]);
    return (
        <>
            <Header />
            <div className={styles.survey}>
                <h1 className={styles.surveyTitleText}>{t('survey_text')}</h1>
                {surveyQuestions && <SurveyInputForm surveyQuestions={surveyQuestions} />}
            </div>
        </>
    );
};

export default Survey;
