import React, { ReactElement, useEffect, useState } from 'react';
import { getCurrentDate, getCurrentTime } from 'intl';
import styles from './clock.module.scss';
import { useTranslation } from 'react-i18next';

const Clock = (): ReactElement => {
    const { i18n } = useTranslation();
    const [date, setDate] = useState(getCurrentDate(i18n.language));
    const [time, setTime] = useState(getCurrentTime(i18n.language));

    useEffect(() => {
        const timerID = setInterval(() => {
            setDateAndTime();
        }, 1000);
        return () => clearInterval(timerID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDateAndTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const setDateAndTime = () => {
        setDate(getCurrentDate(i18n.language));
        setTime(getCurrentTime(i18n.language));
    };

    return (
        <div className={styles.clock}>
            <span>{time}</span>|<span className={styles.clockDateText}>{date}</span>
        </div>
    );
};

export default Clock;
