import React, { FC, useState } from 'react';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { useSettings } from 'hooks/SettingsContext';
import { useTimer } from 'hooks/TimerContext';
import useWindowSize from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { FormData, SurveyQuestion } from 'types';
import styles from './surveyInputForm.module.scss';
import SurveyInput from 'components/SurveyInput';

type SurveyInputFormProps = {
    surveyQuestions: SurveyQuestion[];
};

const SurveyInputForm: FC<SurveyInputFormProps> = ({ surveyQuestions }) => {
    const [formData, setFormData] = useState<FormData>({});
    const { t } = useTranslation();
    const { isMobile } = useWindowSize();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { startTimer } = useTimer();
    const { appointmentId } = useParams();
    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                if (!settings || !appointmentId) return;
                const db = getFirestore();
                const docRef = doc(db, 'appointments', appointmentId);
                await updateDoc(docRef, { survey: formData });
                const nextPage = isMobile
                    ? settings.features.pageFlow.survey.nextMobile
                    : settings.features.pageFlow.survey.next;
                navigate(`${nextPage}/${appointmentId}`);
                if (!isMobile) {
                    const secondsTilRedirect = settings.features.timer;
                    startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                }
            }}
            className={styles.surveyInputContainer}
        >
            {surveyQuestions.map(({ id, inputType, placeholder, labelText }) => (
                <SurveyInput
                    key={id}
                    type={inputType}
                    labelText={labelText}
                    placeholder={placeholder}
                    value={formData[id] ?? ''}
                    onChange={(e) => setFormData({ ...formData, [id]: e.target.value })}
                />
            ))}
            <button
                disabled={Object.entries(formData ?? {}).some((entry) => !entry[1])}
                type="submit"
                className={styles.surveyButton}
            >
                {t('send')}
            </button>
        </form>
    );
};

export default SurveyInputForm;
