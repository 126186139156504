import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './checkInSuccessful.module.scss';
import noShoes from 'assets/no_shoes.webp';
import handSanitizer from 'assets/hand_sanitizer.svg';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'hooks/TimerContext';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { toDataURL } from 'qrcode';
import { useAuth } from 'hooks/AuthContext';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Appointment } from 'types';

const CheckInSuccessful: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { startTimer } = useTimer();
    const { isMobile } = useWindowSize();
    const { appointmentId } = useParams();
    const { clinic } = useAuth();
    const [qrCodeImg, setQrCodeImg] = useState<string>();
    const [surveyComplete, setSurveyComplete] = useState(true);

    useEffect(() => {
        const opts = {
            quality: 0.3,
            margin: 1,
            color: {
                dark: '#ffffff00',
                light: '#ffffffee',
            },
        };
        const getQrCode = async (appointmentId: string) => {
            const nextPage = settings?.features.pageFlow.checkInSuccessful.nextMobile;
            const qrCode = await toDataURL(
                `${window.location.origin}/qr/${clinic}/${appointmentId}${nextPage}`,
                opts
            );
            setQrCodeImg(qrCode);
        };
        if (!appointmentId) return;
        getQrCode(appointmentId);
    }, [appointmentId, clinic, settings]);

    useEffect(() => {
        const isSurveyComplete = async (appointmentId: string) => {
            const db = getFirestore();
            const docRef = doc(db, 'appointments', appointmentId);
            const docData = await getDoc(docRef);
            const data = docData.data() as Appointment;
            setSurveyComplete(!!data.survey);
        };
        if (!appointmentId) return;
        isSurveyComplete(appointmentId);
    }, [appointmentId, clinic, settings]);

    const infoIcons = [
        { img: noShoes, translationKey: 'check_in_successful_step_1' },
        { img: handSanitizer, translationKey: 'check_in_successful_step_2' },
    ];

    return (
        <>
            <Header />
            <div className={styles.wrapper}>
                <p className={styles.checkInSuccessfulHeaderText}>
                    {t('check_in_successful_header')}
                </p>
                <p className={styles.checkInSuccessfulStepsText}>
                    {settings?.features.qr && !isMobile && !surveyComplete
                        ? t('before_appointment')
                        : t('survey_successful_steps')}
                </p>
                <div className={styles.checkInSuccessfulIconRow}>
                    {settings?.features.qr && !surveyComplete ? (
                        <div className={styles.checkInSuccessfulIconWrapper}>
                            <div className={styles.checkInSuccessfulIcon}>
                                <img
                                    className={styles.checkInSuccessfulIconImage}
                                    src={qrCodeImg}
                                    alt=""
                                />
                            </div>
                        </div>
                    ) : (
                        infoIcons.map((icon, index) => (
                            <div key={index} className={styles.checkInSuccessfulIconWrapper}>
                                <div className={styles.checkInSuccessfulIcon}>
                                    <img
                                        className={styles.checkInSuccessfulIconImage}
                                        src={icon.img}
                                        alt=""
                                    />
                                </div>
                                <p>{t(icon.translationKey)}</p>
                            </div>
                        ))
                    )}
                </div>

                {!isMobile ? (
                    <button
                        className={styles.checkInSuccessfulNewCheckInButton}
                        onClick={() => {
                            if (!settings) return;
                            if (isMobile) {
                                navigate(settings.features.pageFlow.checkInSuccessful.nextMobile);
                            } else {
                                navigate(settings.features.pageFlow.checkInSuccessful.next);
                                const secondsTilRedirect = settings.features.timer;
                                startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                            }
                        }}
                    >
                        {t('check_in_successful_button_new_checkin')}
                    </button>
                ) : (
                    <p className={styles.checkInSuccessfulMessageText}>
                        {t('close_window_message')}
                    </p>
                )}
            </div>
        </>
    );
};

export default CheckInSuccessful;
