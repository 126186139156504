import React, { ReactElement, FC } from 'react';
import styles from './numpad.module.scss';
import { useTimer } from 'hooks/TimerContext';
import { useNavigate } from 'react-router-dom';
import { useSettings } from 'hooks/SettingsContext';
import useWindowSize from 'hooks/useWindowSize';

const buttonNumber = (index: number): number => {
    return index + 1 < 10 ? index + 1 : 0;
};

type NumpadProps = {
    handleAppend: (value: number) => void;
    handleDelete: () => void;
    hasDisabledNumberButtons: boolean;
    hasDisabledBackspaceButton: boolean;
};

const Numpad: FC<NumpadProps> = ({
    handleAppend,
    handleDelete,
    hasDisabledNumberButtons,
    hasDisabledBackspaceButton,
}): ReactElement => {
    const { startTimer } = useTimer();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { isMobile } = useWindowSize();
    return (
        <div className={styles.numpad} data-testid="numpad">
            <div className={styles.numpadNumberGrid}>
                {[...Array(10)].map((input, index) => {
                    return (
                        <button
                            type="button"
                            className={styles.digit}
                            key={index}
                            onClick={() => {
                                handleAppend(buttonNumber(index));
                                if (!settings) return;
                                if (!isMobile) {
                                    const secondsTilRedirect = settings.features.timer;
                                    startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                                }
                            }}
                            disabled={hasDisabledNumberButtons}
                        >
                            {buttonNumber(index)}
                        </button>
                    );
                })}
            </div>
            <button
                disabled={hasDisabledBackspaceButton}
                onClick={() => {
                    handleDelete();
                    if (!settings) return;
                    if (!isMobile) {
                        const secondsTilRedirect = settings.features.timer;
                        startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                    }
                }}
                className={`${styles.backspace} ${
                    hasDisabledBackspaceButton ? '' : styles.backspaceActive
                }`}
                data-testid="backspace"
            >
                <span className={styles.backspaceText}>×</span>
            </button>
        </div>
    );
};
export default Numpad;
