import React, { FC, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from 'pages/Login';
import Screensaver from 'pages/Screensaver';
import CheckIn from 'pages/CheckIn';
import CheckInSuccessful from 'pages/CheckInSuccessful';
import Survey from 'pages/Survey';
import NotFound from 'pages/NotFound';
import SurveySuccessful from 'pages/SurveySuccessful';
import { useAuth } from 'hooks/AuthContext';
import Loading from 'components/Loading';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { AppVersion, Settings } from 'types';
import { useSettings } from 'hooks/SettingsContext';
import LoginQR from 'pages/LoginQR';

const App: FC = () => {
    const { isLoading, clinic } = useAuth();
    const { setSettings } = useSettings();

    useEffect(() => {
        if (!clinic) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettinigs', clinic);
        const unsubSettings = onSnapshot(docRef, (snapshotSettings) => {
            const settingsData = snapshotSettings.data() as Settings;
            setSettings(settingsData);
        });
        // eslint-disable-next-line consistent-return
        return unsubSettings;
    }, [clinic, setSettings]);

    useEffect(() => {
        const env = process.env.NODE_ENV;
        const db = getFirestore();
        const docRef = doc(db, 'appVersion', env);
        const unsubAppVersion = onSnapshot(docRef, (snapshotAppVersion) => {
            const eventVersion = snapshotAppVersion.data() as AppVersion;
            const versionString = sessionStorage.getItem('version');
            if (!versionString) {
                sessionStorage.setItem('version', JSON.stringify(eventVersion));
            } else {
                const storedVersion = JSON.parse(versionString) as AppVersion;
                if (eventVersion.releasedAt.seconds > storedVersion.releasedAt.seconds) {
                    sessionStorage.setItem('version', JSON.stringify(eventVersion));
                    window.location.reload();
                }
            }
        });
        return unsubAppVersion;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isLoading ? (
        <>
            <Routes>
                <Route path="/qr/:clinicId/:appointmentId/:service" element={<LoginQR />} />
                <Route path="/qr/:clinicId" element={<LoginQR />} />
                <Route path="/login" element={<Login />} />
                {clinic ? (
                    <>
                        <Route path="/" element={<Screensaver />} />
                        <Route path="/screensaver" element={<Screensaver />} />
                        <Route path="/check-in" element={<CheckIn />} />
                        <Route
                            path="/check-in-successful/:appointmentId"
                            element={<CheckInSuccessful />}
                        />
                        <Route path="/survey/:appointmentId" element={<Survey />} />
                        <Route
                            path="/survey-successful/:appointmentId"
                            element={<SurveySuccessful />}
                        />
                        <Route path="*" element={<NotFound />} />
                    </>
                ) : (
                    <Route path="*" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </>
    ) : (
        <Loading />
    );
};

export default App;
