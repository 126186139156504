import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './notFound.module.scss';
const NotFound: FC = () => {
    const { pathname } = useLocation();
    return (
        <div className={styles.notFound}>
            <h1 className={styles.errorCode}>404.</h1>
            <h2>Den begärda sidan "{pathname}" hittades inte.</h2>
            <Link to="/screensaver">Tillbaka till hem</Link>
        </div>
    );
};

export default NotFound;
