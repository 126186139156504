import React, { useEffect } from 'react';
import Loading from 'components/Loading';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/AuthContext';
import { useSettings } from 'hooks/SettingsContext';

const LoginQr: FC = () => {
    const { clinicId, appointmentId, service } = useParams();
    const { setClinic } = useAuth();
    const navigate = useNavigate();
    const { settings } = useSettings();

    useEffect(() => {
        if (!clinicId) return;
        setClinic(clinicId);
    }, [clinicId, setClinic]);

    useEffect(() => {
        if (!settings) return;
        if (!appointmentId || !service) {
            navigate(settings.features.pageFlow.entryPointMobile);
            return;
        }
        navigate(`/${service}/${appointmentId}`);
    }, [settings, navigate, appointmentId, service]);

    return <Loading />;
};

export default LoginQr;
