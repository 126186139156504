import React, { FC, ReactElement } from 'react';
import styles from './securityNumber.module.scss';
type SecurityNumberProps = {
    securityNumber: string;
    hasSecurityNumberError: boolean;
    securityNumberInitials: number[];
};
const SecurityNumber: FC<SecurityNumberProps> = ({
    securityNumber,
    hasSecurityNumberError,
    securityNumberInitials,
}): ReactElement => {
    return (
        <div className={styles.securityNumber} data-testid="security-numbers">
            <div className={styles.inputContainer}>
                {securityNumberInitials.map((number: number, index: number) => {
                    return (
                        <input
                            key={index}
                            readOnly
                            type={'text'}
                            className={`${styles.securityNumberInput} ${styles.securityNumberWrapper}`}
                            value={number}
                        />
                    );
                })}
                {[...Array(10)].map((item, index) => (
                    <div key={index} className={styles.securityNumberWrapper}>
                        <input
                            placeholder={index === securityNumber.length ? '|' : ''}
                            data-testid={`input-test-${index}`}
                            readOnly
                            type={index < 6 ? 'text' : 'password'}
                            className={`${styles.securityNumberInput} ${
                                hasSecurityNumberError ? styles.errorColor : ''
                            }`}
                            value={securityNumber[index] ?? ''}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
export default SecurityNumber;
