import Numpad from 'components/Numpad';
import SecurityNumber from 'components/SecurityNumber';
import React, { FC, useEffect, useState } from 'react';
import personnummer from 'personnummer';
import styles from './checkIn.module.scss';
import { checkInPatient } from 'firebase-service';
import { useNavigate } from 'react-router-dom';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/AuthContext';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { useTimer } from 'hooks/TimerContext';
const CheckIn: FC = () => {
    const { clinic } = useAuth();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const [securityNumber, setSecurityNumber] = useState('');
    const [isCheckInButtonDisabled, setIsCheckInButtonDisabled] = useState(true);
    const [isNumpadDisabled, setIsNumpadDisabled] = useState(false);
    const [hasSecurityNumberError, setHasSecurityNumberError] = useState(false);
    const { t } = useTranslation();
    const { isMobile } = useWindowSize();
    const { startTimer } = useTimer();
    useEffect(() => {
        if (securityNumber.length < 10) {
            setIsNumpadDisabled(false);
            setIsCheckInButtonDisabled(true);
            setHasSecurityNumberError(false);
        } else if (!personnummer.valid(securityNumber)) {
            setIsNumpadDisabled(true);
            setIsCheckInButtonDisabled(true);
            setHasSecurityNumberError(true);
        } else {
            setIsNumpadDisabled(true);
            setIsCheckInButtonDisabled(false);
            setHasSecurityNumberError(false);
        }
    }, [securityNumber]);
    const handleAppend = (value: number) => {
        setSecurityNumber(securityNumber + value);
    };
    const handleDelete = () => {
        setSecurityNumber(securityNumber.substring(0, securityNumber.length - 1));
    };
    return (
        <>
            <Header />
            <div className={styles.checkIn}>
                <h1 className={styles.checkInText}>{t('check_in_text')}</h1>
                <p className={`${styles.errorMessage} ${styles.errorColor}`}>
                    {hasSecurityNumberError && t('check_in_error_invalid_social')}
                </p>
                {isMobile ? (
                    <div className={styles.securityNumberContainer}>
                        <p className={styles.secNumInitials}>20</p>
                        <input
                            type="tel"
                            placeholder="xxxxxxxxxx"
                            value={securityNumber}
                            onChange={(e) => {
                                if (e.target.value.length > 10) return;
                                setSecurityNumber(e.target.value);
                            }}
                            className={styles.securityNumberInput}
                        />
                    </div>
                ) : (
                    <>
                        <SecurityNumber
                            securityNumber={securityNumber}
                            hasSecurityNumberError={hasSecurityNumberError}
                            securityNumberInitials={settings?.features.securityNumberInitials ?? []}
                        />
                        <Numpad
                            handleAppend={handleAppend}
                            handleDelete={handleDelete}
                            hasDisabledNumberButtons={isNumpadDisabled}
                            hasDisabledBackspaceButton={securityNumber.length === 0}
                        />
                    </>
                )}
                <button
                    disabled={isCheckInButtonDisabled}
                    className={styles.checkInButton}
                    onClick={async () => {
                        if (!clinic || !settings) return;
                        const appointmentId = await checkInPatient(securityNumber, clinic);
                        const nextPage = isMobile
                            ? settings.features.pageFlow.checkIn.nextMobile
                            : settings.features.pageFlow.checkIn.next;
                        navigate(`${nextPage}/${appointmentId}`);
                        if (!isMobile) {
                            const secondsTilRedirect = settings.features.timer;
                            startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                        }
                    }}
                >
                    {t('check_in_button')}
                </button>
            </div>
        </>
    );
};
export default CheckIn;
