import LanguageToggle from 'components/LanguageToggle';
import Clock from 'components/Clock';
import React, { FC, useEffect, useState } from 'react';
import styles from './screensaver.module.scss';
import { useNavigate } from 'react-router-dom';
import pointingHand from 'assets/pointing_hand.svg';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'hooks/TimerContext';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-service';
import { useAuth } from 'hooks/AuthContext';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { toDataURL } from 'qrcode';

const Screensaver: FC = () => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { t } = useTranslation();
    const { startTimer } = useTimer();
    const { clinic } = useAuth();
    const { isMobile } = useWindowSize();
    const [qrCodeImg, setQrCodeImg] = useState<string>();

    useEffect(() => {
        const opts = {
            quality: 0.3,
            margin: 1,
            color: {
                dark: '#ffffff00',
                light: '#ffffffee',
            },
        };
        const getQrCode = async (clinic: string) => {
            const qrCode = await toDataURL(window.location.origin + '/qr/' + clinic, opts);
            setQrCodeImg(qrCode);
        };
        if (!clinic) return;
        getQrCode(clinic);
    }, [clinic]);

    return (
        <>
            <Header />
            <div
                className={styles.wrapper}
                onClick={() => {
                    if (!settings) return;
                    logEvent(analytics, 'screensaver_pressed', { clinic });
                    if (isMobile) {
                        navigate(settings.features.pageFlow.screensaver.nextMobile);
                    } else {
                        navigate(settings.features.pageFlow.screensaver.next);
                        const secondsTilRedirect = settings.features.timer;
                        startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                    }
                }}
            >
                <p className={styles.screensaverWelcomeText}>{t('welcome_text')}</p>
                <p className={styles.screensaverInstructions}>
                    {settings?.features.qr
                        ? t('screensaver_instructions_qr')
                        : t('screensaver_instructions')}
                </p>
                <div className={styles.screensaverCircleOuter}>
                    <div className={styles.screensaverCircleInner}>
                        {t('screensaver_start_text')}
                        <div
                            className={`${styles.screensaverPointingHandWrapper} ${styles.screensaverPointingHandWrapperPulse}`}
                        >
                            <img
                                className={styles.screensaverPointingHand}
                                src={pointingHand}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                {qrCodeImg && settings?.features.qr && (
                    <img src={qrCodeImg} className={styles.screensaverQRCode} alt="" />
                )}
                <div className={styles.screensaverClockAndLanguageBar}>
                    <Clock />
                    <LanguageToggle />
                </div>
            </div>
        </>
    );
};

export default Screensaver;
