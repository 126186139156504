import React from 'react';
import { SettingsProvider } from 'hooks/SettingsContext';
import 'dotenv/config';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import App from './App';
import { AuthProvider } from 'hooks/AuthContext/authContext.hook';
import './index.scss';
import { TimerProvider } from 'hooks/TimerContext';

ReactDOM.render(
    <Router>
        <AuthProvider>
            <SettingsProvider>
                <TimerProvider>
                    <App />
                </TimerProvider>
            </SettingsProvider>
        </AuthProvider>
    </Router>,
    document.getElementById('hyperstack-ehealth-client')
);
