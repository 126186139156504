import Header from 'components/Header';
import { useSettings } from 'hooks/SettingsContext';
import useWindowSize from 'hooks/useWindowSize';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './surveySuccessful.module.scss';
import noShoes from '../../assets/no_shoes.webp';
import handSanitizer from '../../assets/hand_sanitizer.svg';
import { useTimer } from 'hooks/TimerContext';

const SurveySuccessful: FC = () => {
    const { t } = useTranslation();
    const infoIcons = [
        { img: noShoes, translationKey: 'check_in_successful_step_1' },
        { img: handSanitizer, translationKey: 'check_in_successful_step_2' },
    ];
    const { isMobile } = useWindowSize();
    const { settings } = useSettings();
    const navigate = useNavigate();
    const { startTimer } = useTimer();
    return (
        <>
            <Header />
            <div className={styles.wrapper}>
                <p className={styles.surveyHeaderText}>{t('survey_successful_header')}</p>
                <p className={styles.surveyMessageText}>{t('survey_successful_message')}</p>
                <p className={styles.surveyStepsText}>{t('survey_successful_steps')}</p>
                <div className={styles.surveyIconsRow}>
                    {infoIcons.map((icon, index) => (
                        <div key={index} className={styles.surveyIconWrapper}>
                            <div className={styles.surveyIcon}>
                                <img className={styles.surveyIconImage} src={icon.img} alt="" />
                            </div>
                            <p>{t(icon.translationKey)}</p>
                        </div>
                    ))}
                </div>
                {isMobile ? (
                    <p className={styles.surveyMessageText}>{t('close_window_message')}</p>
                ) : (
                    <button
                        className={styles.surveySuccessfulNewCheckInButton}
                        onClick={() => {
                            if (!settings) return;
                            navigate(settings.features.pageFlow.surveySuccessful.next);
                            const secondsTilRedirect = settings.features.timer;
                            startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                        }}
                    >
                        {t('check_in_successful_button_new_checkin')}
                    </button>
                )}
            </div>
        </>
    );
};

export default SurveySuccessful;
