import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './languageToggle.module.scss';
import english from 'assets/english.png';
import swedish from 'assets/swedish.png';

const LanguageToggle = (): ReactElement => {
    const { i18n } = useTranslation();
    const icons: { [key: string]: string } = { sv: swedish, en: english };
    const languages = [...i18n.languages];
    return (
        <div className={styles.wrapper}>
            {languages
                .sort((a, b) => (a > b ? 1 : -1))
                .map((langKey) => (
                    <span
                        key={langKey}
                        className={styles.language}
                        onClick={(e) => {
                            e.stopPropagation();
                            i18n.changeLanguage(langKey);
                        }}
                    >
                        <img
                            alt=""
                            src={icons[langKey]}
                            className={
                                langKey === i18n.language ? styles.iconActive : styles.iconDisabled
                            }
                        />
                    </span>
                ))}
        </div>
    );
};

export default LanguageToggle;
