import React, { ChangeEvent, FC } from 'react';
import styles from './surveyInput.module.scss';

type SurveyInputProps = {
    labelText: string;
    value: string;
    placeholder: string;
    type: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const SurveyInput: FC<SurveyInputProps> = ({ labelText, value, placeholder, type, onChange }) => {
    return (
        <label className={styles.surveyInputLabel}>
            {labelText}
            <input
                className={styles.surveyNumberInput}
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
            />
        </label>
    );
};

export default SurveyInput;
